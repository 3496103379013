
export default {
    name: 'productSecurity',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            shopping: {
                list: []
            },
            lang: {
                identify: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getAppHeaders()
        this.initialSet()
    },
    methods: {
        handleRight(val) {
            let name = '', query = '';
            switch(+val) {
                case 1:
                    name = 'jumpOrderList'
                    break;
                case 2:
                    name = 'Policy'
                    query = {id: '1679455454190GMmYwUO3'}
                    break;
                case 3:
                    name = 'ContactUs'
                    break;
                case 4:
                    name = 'Policy'
                    query = {id: '16793696198020vLSBIcB'}
                    break;
                default:
            }
            if(val != 1) {
                this.$router.push({
                    name,
                    query
                })
            } else {
                this.$fnApi.bridgeApp.bridge({
                    name: name
                })
            }
        },
        // 获取 app 信息
        getAppHeaders() {
            this.$fnApi.bridgeApp.bridge({
                name: 'getHeaders',
                hasGetData: true
            }).then(e => {
                delete this.app.headers.loginTerminal
                delete this.app.headers.currency
                this.lang.identify = e.language
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Shopping Security'),
                keywords: this.$translate('Shopping Security'),
                description: this.$translate('Shopping Security'),
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.shopping.list = [{
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689645736523.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689645760834.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689648901882.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689648914656.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689648964465.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649574394.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649583283.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649586759.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649594490.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649139775.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649150058.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1689649172476.png'
            }]
        }
    },
}

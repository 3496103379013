
export default {
    name: 'ProductProp',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            list: [],
            info: {},
            params: {
                productCode: '',
                seoUrl: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getAppConfig()
    },
    methods: {
        // 商品详情接口
        getDetailAjax() {
            this.$api.product.getDetail({
                productCode: this.params.productCode,
            }).then(response => {
                const res = response.result || {};
                this.info = res
                this.list = [{
                    propName: this.$translate('Product ID'),
                    propValue: this.params.productCode
                }]
                this.info.props.forEach(item => {
                    this.list.push({
                        propName: item.propName,
                        propValue: item.propValue.join(', ')
                    })
                })
            })
        },
        // 获取app传递参数
        getAppConfig() {
            this.$fnApi.bridgeApp.bridge({
                name: 'getConfig',
                hasGetData: true
            }).then(e => {
                this.params.productCode = e.productCode
                this.getDetailAjax()
            })
        },
        // 复制
        handleCopy() {
            this.$fnApi.copy.initial(this.params.productCode)
            this.$Toast('Copied')
        }
    },
}

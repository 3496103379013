
export default {
    name: 'productShipping',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            country: {},
            currency: {},
            detail: {},
            popup: {
                visible: false,
                shippingList: []
            },
            shipping: {
                list: []
            },
            app: {
                headers: {}
            },
            lang: {
                identify: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getAppConfig()
        this.getAppHeaders()
    },
    methods: {
        getShippingAjax() {
            this.$api.common.getShipping({}, this.app.headers).then(response => {
                const res = response.result;
                if(!res) {
                    return
                }
                this.shipping.list = []
                this.popup.shippingList = []
                res.forEach(item => {
                    // 运输协议
                    let priceText = !item.fee ? 'Free' : `${this.$price(item.displayFee, this.currency.symbol)}`
                    let maxPriceText = `${this.$price(item.displayFreeLimit, this.currency.symbol)}`
                    let deliverTime = `${item.minDayStr.split('/')[0]}.${item.minDayStr.split('/')[1]}~${item.maxDayStr.split('/')[0]}.${item.maxDayStr.split('/')[1]}`
                    this.shipping.list.push({
                        text: `${item.name}: ${priceText}, shipping over ${maxPriceText} Estimated to be delivered on ${deliverTime}`
                    })
                    // 运输协议弹窗
                    this.popup.shippingList.push({
                        title: this.$translate(item.name, this.lang.identify),
                        time: `${item.minDay}-${item.maxDay} ${this.$translate('workdays', this.lang.identify)}`,
                        displayFee: `${this.$price(item.displayFee, this.currency.symbol)}`,
                        text: !item.fee ? this.$translate('Free', this.lang.identify) : `${this.$translate('Free on orders over', this.lang.identify)} ${maxPriceText}`
                    })
                })
            })
        },
        goShipping() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '1679369506448H4KdPCGQ'
                }
            })
        },
        // 获取 app 信息
        getAppConfig() {
            this.$fnApi.bridgeApp.bridge({
                name: 'getConfig',
                hasGetData: true
            }).then(e => {
                this.currency.symbol = '$'
                if(e.currency) {
                    this.currency.symbol = e.currency
                }
                this.detail = e.detail || {}
                this.country = e.country || {}
            })
        },
        getAppHeaders() {
            this.$fnApi.bridgeApp.bridge({
                name: 'getHeaders',
                hasGetData: true
            }).then(e => {
                this.app.headers = e
                delete this.app.headers.loginTerminal
                delete this.app.headers.currency
                this.lang.identify = e.language
                // shippingProduct
                this.getShippingAjax()
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Shipping Policy'),
                keywords: this.$translate('Shipping Policy'),
                description: this.$translate('Shipping Policy'),
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {

        }
    },
}

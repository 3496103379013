
export default {
    name: 'ProductSize',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            table: {
                list: [],
                titleList: [],
            },
            desc: {
                list: []
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        initialSet() {
            this.table.titleList = [{
                key: 1,
                name: 'In'
            }, {
                key: 2,
                name: 'MM'
            }, {
                key: 3,
                name: 'US,Can,Mx'
            }, {
                key: 4,
                name: 'UK,AUS,<br>IRL,NT,SA'
            }, {
                key: 5,
                name: 'FR'
            }, {
                key: 6,
                name: 'GER,RUS,<br>UA,ASIA'
            }, {
                key: 7,
                name: 'IND,CHN,JPN,<br>SA,TUR,ISR'
            }, {
                key: 8,
                name: 'ITA,ES,NL,CH'
            }]
            this.table.list = [{
                key: 1,
                list: [{
                    name: '1.74'
                }, {
                    name: '44.2'
                }, {
                    name: '3'
                }, {
                    name: 'F'
                }, {
                    name: '44'
                }, {
                    name: '14'
                }, {
                    name: '4'
                }, {
                    name: '4'
                }]
            }, {
                key: 2,
                list: [{
                    name: '1.77'
                }, {
                    name: '44.8'
                }, {
                    name: '3.25'
                }, {
                    name: 'F 3/4'
                }, {
                    name: '44.625'
                }, {
                    name: '14.25'
                }, {
                    name: '--'
                }, {
                    name: '4.625'
                }]
            }, {
                key: 3,
                list: [{
                    name: '1.79'
                }, {
                    name: '45.5'
                }, {
                    name: '3.5'
                }, {
                    name: 'G 1/4'
                }, {
                    name: '45.25'
                }, {
                    name: '14.5'
                }, {
                    name: '5'
                }, {
                    name: '5.25'
                }]
            }, {
                key: 4,
                list: [{
                    name: '1.82'
                }, {
                    name: '46.1'
                }, {
                    name: '3.75'
                }, {
                    name: 'H'
                }, {
                    name: '45.875'
                }, {
                    name: '14.75'
                }, {
                    name: '6'
                }, {
                    name: '5.875'
                }]
            }, {
                key: 5,
                list: [{
                    name: '1.84'
                }, {
                    name: '46.8'
                }, {
                    name: '4'
                }, {
                    name: 'H 1/2'
                }, {
                    name: '46.5'
                }, {
                    name: '15'
                }, {
                    name: '7'
                }, {
                    name: '6.5'
                }]
            }, {
                key: 6,
                list: [{
                    name: '1.87'
                }, {
                    name: '47.4'
                }, {
                    name: '4.25'
                }, {
                    name: 'I'
                }, {
                    name: '47.125'
                }, {
                    name: '15.25'
                }, {
                    name: '--'
                }, {
                    name: '7.125'
                }]
            }, {
                key: 7,
                list: [{
                    name: '1.89'
                }, {
                    name: '48'
                }, {
                    name: '4.5'
                }, {
                    name: 'I 1/2'
                }, {
                    name: '47.75'
                }, {
                    name: '15.5'
                }, {
                    name: '8'
                }, {
                    name: '7.75'
                }]
            }, {
                key: 8,
                list: [{
                    name: '1.92'
                }, {
                    name: '48.7'
                }, {
                    name: '4.75'
                }, {
                    name: 'J 1/4'
                }, {
                    name: '48.375'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '8.375'
                }]
            }, {
                key: 9,
                list: [{
                    name: '1.94'
                }, {
                    name: '49.3'
                }, {
                    name: '5'
                }, {
                    name: 'J 1/2'
                }, {
                    name: '49'
                }, {
                    name: '15.75'
                }, {
                    name: '9'
                }, {
                    name: '9'
                }]
            }, {
                key: 10,
                list: [{
                    name: '1.97'
                }, {
                    name: '50'
                }, {
                    name: '5.25'
                }, {
                    name: 'K 1/4'
                }, {
                    name: '49.625'
                }, {
                    name: '16'
                }, {
                    name: '--'
                }, {
                    name: '9.625'
                }]
            }, {
                key: 11,
                list: [{
                    name: '1.99'
                }, {
                    name: '50.6'
                }, {
                    name: '5.5'
                }, {
                    name: 'L'
                }, {
                    name: '50.25'
                }, {
                    name: '16.25'
                }, {
                    name: '10'
                }, {
                    name: '10.25'
                }]
            }, {
                key: 12,
                list: [{
                    name: '2.02'
                }, {
                    name: '51.2'
                }, {
                    name: '5.75'
                }, {
                    name: 'L 1/4'
                }, {
                    name: '50.875'
                }, {
                    name: '--'
                }, {
                    name: '11'
                }, {
                    name: '10.875'
                }]
            }, {
                key: 13,
                list: [{
                    name: '2.04'
                }, {
                    name: '51.9'
                }, {
                    name: '6'
                }, {
                    name: 'M'
                }, {
                    name: '51.5'
                }, {
                    name: '16.5'
                }, {
                    name: '12'
                }, {
                    name: '11.5'
                }]
            }, {
                key: 14,
                list: [{
                    name: '2.07'
                }, {
                    name: '52.5'
                }, {
                    name: '6.25'
                }, {
                    name: 'M 1/2'
                }, {
                    name: '52.125'
                }, {
                    name: '16.75'
                }, {
                    name: '--'
                }, {
                    name: '12.125'
                }]
            }, {
                key: 15,
                list: [{
                    name: '2.09'
                }, {
                    name: '53.1'
                }, {
                    name: '6.5'
                }, {
                    name: 'N'
                }, {
                    name: '52.75'
                }, {
                    name: '17'
                }, {
                    name: '13'
                }, {
                    name: '12.75'
                }]
            }, {
                key: 16,
                list: [{
                    name: '2.12'
                }, {
                    name: '53.8'
                }, {
                    name: '6.75'
                }, {
                    name: 'N 1/2'
                }, {
                    name: '53.375'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '13.375'
                }]
            }, {
                key: 17,
                list: [{
                    name: '2.14'
                }, {
                    name: '54.4'
                }, {
                    name: '7'
                }, {
                    name: 'O'
                }, {
                    name: '54'
                }, {
                    name: '17.25'
                }, {
                    name: '14'
                }, {
                    name: '14'
                }]
            }, {
                key: 18,
                list: [{
                    name: '2.17'
                }, {
                    name: '55.1'
                }, {
                    name: '7.25'
                }, {
                    name: 'O 1/2'
                }, {
                    name: '54.625'
                }, {
                    name: '17.5'
                }, {
                    name: '--'
                }, {
                    name: '14.625'
                }]
            }, {
                key: 19,
                list: [{
                    name: '2.19'
                }, {
                    name: '55.7'
                }, {
                    name: '7.5'
                }, {
                    name: 'P'
                }, {
                    name: '55.25'
                }, {
                    name: '17.75'
                }, {
                    name: '15'
                }, {
                    name: '15.25'
                }]
            }, {
                key: 20,
                list: [{
                    name: '2.22'
                }, {
                    name: '56.3'
                }, {
                    name: '7.75'
                }, {
                    name: 'P 1/2'
                }, {
                    name: '55.875'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '15.875'
                }]
            }, {
                key: 21,
                list: [{
                    name: '2.24'
                }, {
                    name: '57'
                }, {
                    name: '8'
                }, {
                    name: 'Q'
                }, {
                    name: '56.5'
                }, {
                    name: '18'
                }, {
                    name: '16'
                }, {
                    name: '16.5'
                }]
            }, {
                key: 22,
                list: [{
                    name: '2.27'
                }, {
                    name: '57.6'
                }, {
                    name: '8.25'
                }, {
                    name: 'Q 1/2'
                }, {
                    name: '57.125'
                }, {
                    name: '18.25'
                }, {
                    name: '--'
                }, {
                    name: '7.125'
                }]
            }, {
                key: 23,
                list: [{
                    name: '2.29'
                }, {
                    name: '58.3'
                }, {
                    name: '8.5'
                }, {
                    name: 'Q 1/4'
                }, {
                    name: '57.75'
                }, {
                    name: '18.5'
                }, {
                    name: '17'
                }, {
                    name: '17.75'
                }]
            }, {
                key: 24,
                list: [{
                    name: '2.32'
                }, {
                    name: '58.9'
                }, {
                    name: '8.75'
                }, {
                    name: 'R 1/4'
                }, {
                    name: '58.375'
                }, {
                    name: '18.75'
                }, {
                    name: '--'
                }, {
                    name: '18.375'
                }]
            }, {
                key: 25,
                list: [{
                    name: '2.34'
                }, {
                    name: '59.5'
                }, {
                    name: '9'
                }, {
                    name: 'R 3/4'
                }, {
                    name: '59'
                }, {
                    name: '19'
                }, {
                    name: '18'
                }, {
                    name: '19'
                }]
            }, {
                key: 26,
                list: [{
                    name: '2.37'
                }, {
                    name: '60.2'
                }, {
                    name: '9.25'
                }, {
                    name: 'S 1/4'
                }, {
                    name: '59.625'
                }, {
                    name: '19.25'
                }, {
                    name: '--'
                }, {
                    name: '19.625'
                }]
            }, {
                key: 27,
                list: [{
                    name: '2.39'
                }, {
                    name: '60.8'
                }, {
                    name: '9.5'
                }, {
                    name: 'S 3/4'
                }, {
                    name: '60.25'
                }, {
                    name: '19.5'
                }, {
                    name: '19'
                }, {
                    name: '20.25'
                }]
            }, {
                key: 28,
                list: [{
                    name: '2.42'
                }, {
                    name: '61.4'
                }, {
                    name: '9.75'
                }, {
                    name: 'T 1/4'
                }, {
                    name: '60.875'
                }, {
                    name: '19.75'
                }, {
                    name: '--'
                }, {
                    name: '20.875'
                }]
            }, {
                key: 29,
                list: [{
                    name: '2.44'
                }, {
                    name: '62.1'
                }, {
                    name: '10'
                }, {
                    name: 'T 1/2'
                }, {
                    name: '61.5'
                }, {
                    name: '20'
                }, {
                    name: '20'
                }, {
                    name: '21.25'
                }]
            }, {
                key: 30,
                list: [{
                    name: '2.47'
                }, {
                    name: '62.7'
                }, {
                    name: '10.25'
                }, {
                    name: 'U'
                }, {
                    name: '62.125'
                }, {
                    name: '20.25'
                }, {
                    name: '21'
                }, {
                    name: '22.125'
                }]
            }, {
                key: 31,
                list: [{
                    name: '2.49'
                }, {
                    name: '63.4'
                }, {
                    name: '10.5'
                }, {
                    name: 'U 1/2'
                }, {
                    name: '62.75'
                }, {
                    name: '20.5'
                }, {
                    name: '22'
                }, {
                    name: '22.75'
                }]
            }, {
                key: 32,
                list: [{
                    name: '2.52'
                }, {
                    name: '64'
                }, {
                    name: '10.75'
                }, {
                    name: 'V 1/4'
                }, {
                    name: '63.375'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '23.375'
                }]
            }, {
                key: 33,
                list: [{
                    name: '2.54'
                }, {
                    name: '64.6'
                }, {
                    name: '11'
                }, {
                    name: 'V 1/2'
                }, {
                    name: '64'
                }, {
                    name: '20.75'
                }, {
                    name: '23'
                }, {
                    name: '24'
                }]
            }, {
                key: 34,
                list: [{
                    name: '2.57'
                }, {
                    name: '65.3'
                }, {
                    name: '11.25'
                }, {
                    name: 'W 1/4'
                }, {
                    name: '64.625'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '24.625'
                }]
            }, {
                key: 35,
                list: [{
                    name: '2.59'
                }, {
                    name: '65.9'
                }, {
                    name: '11.5'
                }, {
                    name: 'W 3/4'
                }, {
                    name: '65.25'
                }, {
                    name: '21'
                }, {
                    name: '24'
                }, {
                    name: '25.25'
                }]
            }, {
                key: 36,
                list: [{
                    name: '2.62'
                }, {
                    name: '66.6'
                }, {
                    name: '11.75'
                }, {
                    name: 'X 1/4'
                }, {
                    name: '65.875'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '25.875'
                }]
            }, {
                key: 37,
                list: [{
                    name: '2.65'
                }, {
                    name: '67.2'
                }, {
                    name: '12'
                }, {
                    name: 'Y'
                }, {
                    name: '66.5'
                }, {
                    name: '21.25'
                }, {
                    name: '25'
                }, {
                    name: '26.5'
                }]
            }, {
                key: 38,
                list: [{
                    name: '2.68'
                }, {
                    name: '68.1'
                }, {
                    name: '12.25'
                }, {
                    name: 'Y 1/2'
                }, {
                    name: '67.125'
                }, {
                    name: '21.5'
                }, {
                    name: '--'
                }, {
                    name: '27.125'
                }]
            }, {
                key: 39,
                list: [{
                    name: '2.71'
                }, {
                    name: '68.5'
                }, {
                    name: '12.5'
                }, {
                    name: 'Z'
                }, {
                    name: '67.75'
                }, {
                    name: '21.75'
                }, {
                    name: '26'
                }, {
                    name: '27.75'
                }]
            }, {
                key: 40,
                list: [{
                    name: '2.72'
                }, {
                    name: '69.1'
                }, {
                    name: '12.75'
                }, {
                    name: 'Z 1/2'
                }, {
                    name: '68.375'
                }, {
                    name: '--'
                }, {
                    name: '--'
                }, {
                    name: '28.375'
                }]
            }, {
                key: 41,
                list: [{
                    name: '2.75'
                }, {
                    name: '69.7'
                }, {
                    name: '13'
                }, {
                    name: 'Z+1'
                }, {
                    name: '69'
                }, {
                    name: '22'
                }, {
                    name: '27'
                }, {
                    name: '29'
                }]
            }]
            this.desc.list = [{
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1689650492279.png',
                title: 'Step1',
                desc: 'Use a piece of string or dental floss and wrap it around the base of your finger.'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1689650501838.png',
                title: 'Step2',
                desc: 'Use a pen to mark the point on the string where the end meets.'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1689650501838.png',
                title: 'Step3',
                desc: 'Use a millimeter ruler to measure the string.'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1689650507688.png',
                title: 'Step4',
                desc: 'Choose the closest measurement to the chart below to find your ring size.'
            }]
        }
    },
}
